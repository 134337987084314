import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import messageGenerator from "@soltell/soltell-message-generator";

import * as sms from "../../services/Sms";
import IssueItem from "./IssueItem";
import MessageLogItem from "../MessagesManual/MessageLogItem";
import LocalStorageKeys from "../../localStorageKeys";
import DatePickerCombo from "../DatePickerCombo";

import { sendData } from "../../services/sendData";

const { fixedTs, extractIssues } = messageGenerator;

const ISSUES_DATA = Object.fromEntries(
  Object.entries(messageGenerator.getIssuesData()).map(([key, val]) => {
    const sortedVal = [
      ["", { msg: "", severity: 0 }],
      ["ok", { msg: "", severity: 0 }],
      ...Object.entries(val),
    ]
      .map(([status, data]) => ({ status, data }))
      .sort(({ data: d1 }, { data: d2 }) => d1.severity - d2.severity);
    return [key, sortedVal];
  })
);

const ARRAY_ISSUE_MAPPER = [
  { dbName: "if_invdeg", messageGenName: "if-invdeg-" },
  { dbName: "if_pow", messageGenName: "if-pow-" },
  { dbName: "if_inv_n", messageGenName: "if-inv-" },
];

const STRING_ISSUE_MAPPER = [
  { dbName: "f_mcm", messageGenName: "f-mcm" },
  { dbName: "f_shadow", messageGenName: "f-shadow" },
  { dbName: "f_erange", messageGenName: "f-erange" },
  { dbName: "f_prange", messageGenName: "f-prange" },
  { dbName: "f_vgrid", messageGenName: "f-vgrid" },
];

function extractArrayIssues(data) {
  const newData = {};
  for (const { dbName, messageGenName } of ARRAY_ISSUE_MAPPER) {
    if (Array.isArray(data[dbName])) {
      Object.assign(
        newData,
        data[dbName].reduce((acc, val, i) => {
          acc[`${messageGenName}${i + 1}`] = val;
          return acc;
        }, {})
      );
    }
  }

  return newData;
}

function extractStringIssues(data) {
  const newData = {};
  for (const { dbName, messageGenName } of STRING_ISSUE_MAPPER) {
    newData[messageGenName] = data[dbName];
  }

  return newData;
}

function extractIssuesDb(data, inverters) {
  if (data) {
    const newData = {
      ...extractStringIssues(data),
      ...extractArrayIssues(data),
    };

    return extractIssues(newData, inverters, []).map((issue) => {
      issue.dbId = data.id;
      return issue;
    });
  }
  return [];
}

const TRANSLATOR_VERSION = messageGenerator.version();

const DB_DATE_FORMAT = "YYYY-MM-DD";

const BASE_SEVERITY_THRESHOLD = 1;
const WARNING_SEVERITY_THRESHOLD = 2;
const ALERT_SEVERITY_THRESHOLD = 3;

const LIST_SEPERATOR = ",";
const DEFAULT_LNG = "he";

const ROLES_TO_SEND = ["admin", "client", "whitelabel"];
const ALERTS_TO_SEND = [
  // "channelStopAlert",
  // "commStopAlert",
  "invStopAlert",
  // "subchannelStopAlert",
];

const Section = styled.div`
  padding: 10px 0;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
`;

const getCleanIssueState = (
  id,
  issue,
  label = "",
  phonesString = "",
  emailsString = ""
) => {
  return {
    id: id,
    shouldSend: false,
    label: label,
    phonesString: phonesString,
    emailsString: emailsString,
    lng: DEFAULT_LNG,
    ...issue,
  };
};

const LOG_STATUS = {
  pending: "pending",
  sending: "sending",
  success: "success",
  failure: "failure",
};

// constructor for object representing a message log
function MessageLog(
  sysId,
  recipient,
  emails,
  status,
  body,
  sender,
  msgCode,
  dataDate,
  error = null
) {
  this.recipientNumber = [...recipient]; // recipient number
  this.emails = [...emails]; // recipient emails
  this.singlePhoneError = recipient.map(() => null); // errors for individual phone number
  this.singlePhoneStatus = recipient.map(() => status); // status for individual phone number
  this.messageId = recipient.map(() => ""); // aws message ids
  this.sysId = sysId || "";
  this.type = sms.getMessageTypes()[0]; // type of the message
  this.status = status; // log status
  this.body = body; // message body
  this.sender = sender; // sender alias
  this.msgCode = msgCode; // code of the automatic message
  this.error = error; // error if exists
  this.lastEdit = moment();
  this.dataDate = dataDate;
}

class BackendMessagesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoading: true,
      data: null,
      selectedMoment: moment(),
      dataMoment: null,
      dataUpdateMoment: null,
      issues: [],
      sendQueue: [],
      sendPromiseIdx: null,
      invalidConfiguration: false,
      showTestSystems: false,
    };
  }

  /**
   * returns an object with 2 keys
   * sysMatch - an object connecting sysIds with related orgs
   * orgMatch - an object connecting orgs with eligible user ids
   */
  matchOrgsToUsers = (orgsData, usersData, systems) => {
    const orgMatch = Object.fromEntries(
      Object.entries(orgsData).map(([orgId, orgObj]) => [
        orgId,
        { ...orgObj, users: [] },
      ])
    );
    const sysMatch = Object.entries(usersData).reduce(
      (sysMatch, [phone, user]) => {
        if (ROLES_TO_SEND.includes(user.role)) {
          const orgId = user.organization;
          orgMatch[orgId].users.push(phone);

          orgsData[orgId].systemIds.forEach((sysId) => {
            if (!sysMatch[sysId]) {
              sysMatch[sysId] = [];
            }
            if (!sysMatch[sysId].includes(orgId)) {
              sysMatch[sysId].push(orgId);
            }
          });
        }
        return sysMatch;
      },
      Object.fromEntries(Object.keys(systems).map((sysId) => [sysId, []]))
    );

    return { orgMatch, sysMatch };
  };

  extractAllIssues = (rawData, systems, orgsData, usersData) => {
    const { sysMatch, orgMatch } = this.matchOrgsToUsers(
      orgsData,
      usersData,
      systems
    );
    const invalidSystems = Object.entries(sysMatch).reduce(
      (invalidSystems, [sysId, orgs]) => {
        // if system has no orgs associated to it, save it as misconfigured
        if (!orgs.length) {
          invalidSystems.push(sysId);
        }
        return invalidSystems;
      },
      []
    );
    const invalidOrgs = Object.entries(orgMatch).reduce(
      (invalidOrgs, [orgId, orgObj]) => {
        const noUsers = !orgObj.users.length;
        const noSystems = !orgObj.systemIds.length;
        if (noUsers || noSystems) {
          invalidOrgs.push({ id: orgId, noUsers, noSystems });
        }
        return invalidOrgs;
      },
      []
    );
    const invalidConfiguration = !!(
      invalidSystems.length || invalidOrgs.length
    );
    if (invalidConfiguration) {
      console.warn("invalid configuration object", {
        invalidSystems,
        invalidOrgs,
      });
    }
    const result = rawData.reduce((issues, data) => {
      const id = data.systemId;
      const sys = systems[id];
      if (!sys) {
        return issues;
      }
      const rawSystemIssues = extractIssuesDb(data, sys.inverters);
      if (rawSystemIssues.length !== 0) {
        // get label and phones and emails for a system
        const { phones, emails, label } = sysMatch[id].reduce(
          (acc, sysOrgId) => {
            orgMatch[sysOrgId].users.forEach((phone) => {
              const user = usersData[phone];
              const presetSms = ALERTS_TO_SEND.some((alertType) => {
                return user.alerts.toSend[alertType].methods.sms;
              });
              const presetEmail = ALERTS_TO_SEND.some((alertType) => {
                return user.alerts.toSend[alertType].methods.email;
              });
              if (presetSms || presetEmail) {
                if (presetSms && !acc.phones.includes(phone)) {
                  acc.phones.push(phone);
                }
                if (presetEmail && !acc.emails.includes(user.email)) {
                  acc.emails.push(user.email);
                }
                if (acc.label === null) {
                  acc.label = user.label[0];
                } else if (acc.label !== user.label[0]) {
                  acc.label = "";
                }
              }
            });
            return acc;
          },
          { phones: [], emails: [], label: null }
        );

        const systemIssues = rawSystemIssues
          .map((issue) => {
            return {
              name: sys.name,
              ...getCleanIssueState(
                id,
                issue,
                label || "",
                phones.join(LIST_SEPERATOR),
                emails.join(LIST_SEPERATOR)
              ),
            };
          })
          .filter((issue) => issue.severity > BASE_SEVERITY_THRESHOLD);
        issues.push(...systemIssues);
      }
      return issues;
    }, []);
    return { result, invalidConfiguration };
  };

  fetchBackendData = () => {
    this.setState(
      { isLoading: true, invalidConfiguration: false },
      async () => {
        try {
          const sysIds = [
            ...Object.keys(this.props.testSystems),
            ...Object.keys(this.props.systems),
          ];
          const url = "/api/insights/search-daily";
          const response = await sendData(
            {
              sysIds,
              dates: [this.state.selectedMoment.format(DB_DATE_FORMAT)],
            },
            url,
            localStorage.getItem(LocalStorageKeys.userToken),
            "post"
          );
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          const result = response.data;
          this.setState((state, props) => {
            const { result: issues, invalidConfiguration } =
              this.extractAllIssues(
                result,
                {
                  ...props.testSystems,
                  ...props.systems,
                },
                props.orgsData,
                props.usersData
              );

            return {
              error: null,
              data: result,
              issues,
              invalidConfiguration,
            };
          });
        } catch (err) {
          console.error(err);
          this.setState({
            error: err,
            data: null,
            issues: [],
            invalidConfiguration: false,
          });
        } finally {
          this.setState((state) => ({
            isLoading: false,
            dataUpdateMoment: moment(),
            dataMoment: moment(state.selectedMoment),
          }));
        }
      }
    );
  };

  componentDidMount = () => {
    this._isMounted = true;
    // check if translator is ready, if not force update afterwards
    if ("then" in fixedTs.ready) {
      fixedTs.ready.then(() => this.forceUpdate());
    }

    this.fetchBackendData();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  updateIssueState = (filteredIdx, updateKeys, updateValues) => {
    this.setState((state) => {
      if (!this._isMounted || !state.issues) {
        return;
      }
      // allow multiupdates to avoid many consectutive state changes, ad hoc solution
      if (!Array.isArray(updateKeys)) {
        updateKeys = [updateKeys];
        updateValues = [updateValues];
      }
      const issues = [...state.issues];
      const issue = this.getFilteredIssues()[filteredIdx];
      const issueIdx = issues.findIndex((e) => e === issue);
      updateKeys.forEach(
        (key, idx) => (issues[issueIdx][key] = updateValues[idx])
      );
      // in case severity changes, don't allow send
      if (issues[issueIdx].severity <= BASE_SEVERITY_THRESHOLD) {
        issues[issueIdx].shouldSend = false;
      }
      return {
        issues,
      };
    });
  };

  onSendAll = () => {
    if (!window.confirm("Send all checked messages?")) {
      console.log("cancelled send all");
      return;
    }

    console.log("updating send queue");
    this.setState((state) => {
      if (!this._isMounted) {
        return;
      }
      const sendQueue = [
        ...state.sendQueue,
        ...state.issues
          .filter((issue) => issue.shouldSend)
          .map((issue) => {
            const phones = issue.phonesString
              .split(LIST_SEPERATOR)
              .map((phone) => phone.trim());
            const emails = issue.emailsString
              .split(LIST_SEPERATOR)
              .map((email) => email.trim());
            return new MessageLog(
              issue.id,
              phones,
              emails,
              LOG_STATUS.pending,
              this.createMessageString(issue),
              issue.label,
              issue.msg,
              state.dataMoment
            );
          }),
      ];
      const issues = state.issues.map((issue) => {
        if (issue.shouldSend) {
          issue.sent = true;
        }
        issue.shouldSend = false;
        return issue;
      });
      return {
        sendQueue,
        issues,
      };
    });
  };

  componentDidUpdate = () => {
    if (
      this.state.sendPromiseIdx === null &&
      this.state.sendQueue.some((log) => log.status === LOG_STATUS.pending)
    ) {
      this.setState(
        (state) => {
          // don't move up, data must be fresh at this point
          const sendPromiseIdx = state.sendQueue.findIndex(
            (log) => log.status === LOG_STATUS.pending
          );
          state.sendQueue[sendPromiseIdx].status = LOG_STATUS.sending;
          return {
            sendPromiseIdx,
          };
        },
        () => this.sendMessage(this.state.sendQueue[this.state.sendPromiseIdx])
      );
    }
  };

  sendMessage = (messageLog) => {
    sms
      .sendSMS(
        messageLog.body,
        messageLog.recipientNumber,
        messageLog.sender,
        messageLog.type,
        DEFAULT_LNG,
        messageLog.sysId,
        moment(messageLog.dataDate),
        "open",
        messageLog.emails,
        messageLog.body.body,
        messageLog.lastEdit.format("YYYY-MM-DD HH:mm")
      )
      .then((res) => {
        messageLog.status = LOG_STATUS.success;
        messageLog.messageId = res.map((awsRes) => awsRes.MessageId || "");
        messageLog.messageId.forEach((awsId, idx) => {
          if (!awsId) {
            messageLog.singlePhoneError[idx] = new Error("problem sending");
            messageLog.singlePhoneStatus[idx] = LOG_STATUS.failure;
          } else {
            messageLog.singlePhoneError[idx] = null;
            messageLog.singlePhoneStatus[idx] = LOG_STATUS.success;
          }
        });
      })
      .catch((err) => {
        console.error(err);
        messageLog.status = LOG_STATUS.failure;
        messageLog.error = err;
      })
      .finally(() => {
        if (!this._isMounted) {
          return;
        }
        messageLog.lastEdit = moment();
        this.setState({
          sendPromiseIdx: null,
        });
      });
  };

  getFilteredIssues = () => {
    const { showTestSystems, issues } = this.state;
    const { testSystems } = this.props;
    return issues.filter(
      (i) => showTestSystems || (!showTestSystems && !testSystems[i.id])
    );
  };

  // returns true only if every issue is checked for sending
  shouldSendAll = () => {
    return this.getFilteredIssues().every(
      (issue) => issue.shouldSend || issue.severity <= BASE_SEVERITY_THRESHOLD
    );
  };

  // if every issue is checked for send cancells them, checks them otherwise
  flipSendAll = () => {
    this.setState((state) => {
      if (!this._isMounted) {
        return;
      }
      const changeTo = !this.shouldSendAll();
      this.getFilteredIssues().forEach((i) => {
        if (i.severity > BASE_SEVERITY_THRESHOLD) {
          i.shouldSend = changeTo;
        }
      });
      return {
        issues: [...state.issues],
      };
    });
  };

  createMessageString = (issue) => {
    const t = fixedTs[issue.lng];
    const extraMessage =
      issue.severity >= ALERT_SEVERITY_THRESHOLD
        ? "alert"
        : issue.severity >= WARNING_SEVERITY_THRESHOLD
        ? "warning"
        : "message";
    const message =
      `${t("system")} ${issue.name}: ${t(extraMessage)} - ` +
      t(issue.msg, issue.params);
    return {
      body: message,
      params: {
        version: TRANSLATOR_VERSION,
        msg: issue.msg,
        params: issue.params,
      },
    };
  };

  onChangeDataDate = (newDate) => {
    this.setState({ selectedMoment: moment(newDate) });
  };

  handleChangeShowTestSystems = (e) => {
    this.setState((state, props) => {
      const newState = { showTestSystems: !state.showTestSystems };
      if (!newState.showTestSystems) {
        const { testSystems } = props;
        newState.issues = state.issues.map((i) => {
          if (testSystems[i.id]) {
            i.shouldSend = false;
          }
          return i;
        });
      }
      return newState;
    });
  };

  render() {
    const {
      error,
      isLoading,
      selectedMoment,
      dataUpdateMoment,
      dataMoment,
      showTestSystems,
    } = this.state;

    const filteredIssues = this.getFilteredIssues();

    if (error) {
      return (
        <div>
          <Section>Error loading data, check console (F12)</Section>
          <Section>
            <button onClick={this.fetchBackendData} disabled={isLoading}>
              update now
            </button>
          </Section>
        </div>
      );
    }
    if (isLoading || "then" in fixedTs.ready) {
      return <Section>Loading messages data</Section>;
    }

    return (
      <div>
        <Section>
          <h3>Messages log</h3>
          <DatePickerCombo
            selected={selectedMoment}
            disable={isLoading}
            onChange={this.onChangeDataDate}
            format={"DD/MM/YYYY"}
          />
          <span style={{ float: "right" }}>
            <span>Show test systems: </span>
            <input
              type="checkbox"
              checked={showTestSystems}
              onChange={this.handleChangeShowTestSystems}
            />
          </span>
          <div>
            last updated: {dataUpdateMoment.format("DD-MM-YYYY, HH:mm")}
          </div>
          <div>data date: {dataMoment.format("DD-MM-YYYY")}</div>
          {this.state.invalidConfiguration && (
            <div style={{ color: "red" }}>
              error with configuration, check console
            </div>
          )}
        </Section>
        <Section>
          <button onClick={this.fetchBackendData} disabled={isLoading}>
            update now
          </button>
        </Section>
        {!filteredIssues.length ? (
          <Section>No severe issues</Section>
        ) : (
          <Section>
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={this.shouldSendAll()}
                      onChange={this.flipSendAll}
                    />
                  </th>
                  <th>system-id</th>
                  <th>message</th>
                  <th>&nbsp;</th>
                  <th>label</th>
                  <th>status</th>
                  <th>phones</th>
                  <th>emails</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {filteredIssues.map((issue, idx) => {
                  return (
                    <IssueItem
                      key={idx}
                      thresholdSeverity={BASE_SEVERITY_THRESHOLD}
                      issueData={ISSUES_DATA[issue.issueKey]}
                      idx={idx}
                      messageString={this.createMessageString(issue).body}
                      updateIssue={this.updateIssueState}
                      {...issue}
                    />
                  );
                })}
              </tbody>
            </table>
          </Section>
        )}
        <Section>
          <button onClick={this.onSendAll}>Send all</button>
        </Section>
        <Section>
          <h4>Messages queue</h4>
          <ul>
            {this.state.sendQueue.map((log) => {
              return log.recipientNumber.map((number, idx) => {
                const singleLog = { ...log };
                singleLog.recipientNumber = number;
                singleLog.messageId = log.messageId[idx];
                singleLog.error = log.singlePhoneError[idx];
                singleLog.status = log.singlePhoneStatus[idx];
                return <MessageLogItem key={idx} log={singleLog} />;
              });
            })}
          </ul>
        </Section>
      </div>
    );
  }
}

export default BackendMessagesForm;
